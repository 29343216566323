import service from '@/utils/request'

export const GetData = (data) => {
    return service({
        url: '/api/'+data.code,
        method: 'get',
    })
}

export const GetTreeData = () => {
    return service({
        url: '/api/treedata',
        method: 'get',
    })
}