<template>
  <a href="/old.html" class="subscript">旧版</a>
  <div id="sidebar" class="sidebar">
    <!-- <el-icon class="fold-icon" @click="siderShowClick"><fold /></el-icon> -->
    <el-tree
      v-if="openPanel"
      :data="treedata"
      :props="defaultProps"
      @node-click="handleNodeClick"
      style="margin-top: 30px"
    ></el-tree>
  </div>
</template>
<script>
import { GetTreeData } from "@/apis/data";
// import { Fold } from "@element-plus/icons-vue";
export default {
  name: "SiderBar",
  emits: ["childByValue"],
  components: {},
  data() {
    return {
      siderShow: true,
      label: "",
      openPanel: true,
      treedata: [
        {
          label: "",
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleNodeClick(data) {
      if (data.children == null) {
        this.$emit("childByValue", data.label);
        console.log(data.label);
      }
    },
    async getData() {
      this.openPanel = false;
      const res = await GetTreeData();
      this.treedata = JSON.parse(JSON.stringify(res.data));
      this.openPanel = true;
    },
    siderShowClick() {
      this.siderShow = !this.siderShow;
      this.openPanel = !this.openPanel;
    },
  },
};
</script>

<style scoped lang="less">
.sidebar {
  width: 173px;
  //   background-color: #f8f8f8;
  z-index: 99;
  height: 100%;
  position: absolute;
  border-right: 1px solid #ececec;
}
.sidebar-hidden {
  width: 15px;
  z-index: 99;
  height: 100%;
  position: absolute;
  border-right: 1px solid #ececec;
}
.fold-icon {
  float: right;
  margin-top: 8px;
  cursor: pointer;
}
.subscript {
  text-decoration: none;
  color: #fff;
  height: 20px;
  width: 75px;
  position: absolute;
  right: -20px;
  text-align: center;
  line-height: 20px;
  font-family: "黑体";
  background-color: #0c60ee;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>