<template>
  <sider-bar v-on:childByValue="childByValue"></sider-bar>
  <div class="search-area">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="设备号">
        <el-input v-model="inputData" placeholder="选择或输入设备号" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSelect">查询</el-button>
        <el-button type="primary" @click="autoSelect" v-if="!refresh"
          >开启自动刷新</el-button
        >
        <el-button color="#626aef" plain @click="autoSelect" v-if="refresh"
          >关闭自动刷新</el-button
        >
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table
        :data="tableData"
        stripe
        style="width: 50%; display: inline-block"
        :show-header="false"
        border
      >
        <el-table-column prop="name" label="" width="250" />
        <el-table-column prop="data" label="" width="250" />
      </el-table>
      <el-table
        :data="tableData2"
        stripe
        style="width: 50%; display: inline-block"
        :show-header="false"
        border
      >
        <el-table-column prop="name" label="" width="250" />
        <el-table-column prop="data" label="" width="250" />
      </el-table>
    </div>
  </div>
  <charts-view
    :dataupdate="dataupdate"
    :idata="idata"
    :pfdata="pfdata"
  ></charts-view>
</template>

<script>
import { GetData } from "@/apis/data";
// import { reactive } from "vue";
import { ElNotification } from "element-plus";
import SiderBar from "@/components/SiderBar.vue";
import ChartsView from "@/components/ChartsView.vue";
export default {
  name: "SearchArea",
  components: {
    ChartsView,
    SiderBar,
  },
  data() {
    return {
      refresh: false,
      idata: [],
      dataupdate: false,
      pfdata: [],
      ts: "",
      inputData: "",
      tableData: [
        {
          name: "电压",
          data: "",
        },
        {
          name: "原始电流",
          data: "",
        },
        {
          name: "电流*变比",
          data: "",
        },
        {
          name: "功率因素",
          data: "",
        },
        {
          name: "电能",
          data: "",
        },
      ],
      tableData2: [
        {
          name: "采集点",
          data: "",
        },
        {
          name: "工序名称",
          data: "",
        },
        {
          name: "互感器",
          data: "",
        },
        {
          name: "变比",
          data: "",
        },
        {
          name: "采集时间",
          data: "",
        },
      ],
    };
  },
  methods: {
    childByValue: function (childValue) {
      // childValue就是子组件传过来的值
      //console.log(childValue);
      this.inputData = childValue;
      this.onSelect();
    },
    async onSelect() {
      if (this.inputData == "") {
        this.idata = [];
        this.pfdata = [];
        this.dataupdate = !this.dataupdate;
        return;
      }

      const res = await GetData({ code: this.inputData });
      if (res.code === 200) {
        this.tableData[0].data = res.u;
        this.tableData[1].data = res.i;
        this.tableData[2].data = res.ia;
        this.tableData[3].data = res.pf;
        this.tableData[4].data = res.e;
        this.tableData2[0].data = res.pointcode;
        this.tableData2[1].data = res.process;
        this.tableData2[2].data = res.radio;
        this.tableData2[3].data = res.mutiple;
        this.tableData2[4].data = res.ts;

        if (this.ts != res.ts) {
          this.idata.push(res.ia);
          this.pfdata.push(res.pf);
          this.dataupdate = !this.dataupdate;
          this.ts = res.ts;

          //超过300清零
          if (this.idata.length > 300) {
            this.idata = [];
            this.pfdata = [];
            this.dataupdate = !this.dataupdate;
          }
        }

        //console.log(this.idata);
      }
      if (res.code === 0) {
        this.tableData[0].data = "";
        this.tableData[1].data = "";
        this.tableData[2].data = "";
        this.tableData[3].data = "";
        this.tableData[4].data = "";
        this.tableData2[0].data = "";
        this.tableData2[1].data = "";
        this.tableData2[2].data = "";
        this.tableData2[3].data = "";
        this.tableData2[4].data = "";
        ElNotification({
          title: "提示",
          type: "warning",
          message: "设备编号出错！",
          duration: 800,
        });
      }
      return false;
    },
    getData() {
      if (this.refresh) {
        setTimeout(this.getData, 1000);
      }
      this.onSelect();
    },
    autoSelect() {
      this.refresh = !this.refresh;
      this.getData();
    },
  },
  watch: {
    inputData() {
      this.idata = [];
      this.pfdata = [];
      this.dataupdate = !this.dataupdate;
    },
  },
};
</script>

<style scoped lang="less">
.search-area {
  margin-left: 173px;
  max-height: 100px;

  .demo-form-inline {
    margin: 10px 0 0 15px;
  }
  .table {
    margin: 0 5% 0 5%;
  }
}
</style>