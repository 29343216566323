<template>
  <div :class="fullScreen ? 'charts-view-fullScreen' : 'charts-view'">
    <div style="text-align: center">
      <el-button @click="fullScreen = !fullScreen" v-if="!fullScreen"
        >全屏</el-button
      >
      <el-button
        class="button-selected"
        @click="fullScreen = !fullScreen"
        v-if="fullScreen"
        >退出全屏</el-button
      >
      <el-button @click="arrangeClick">排列</el-button>
      <!-- <el-button>设置</el-button> -->
    </div>
    <div style="text-align: center">
      <div
        id="line_chart"
        :class="!arrange ? 'line-chart' : 'line-chart-display'"
      ></div>
      <div
        id="line_chart2"
        class="line-chart"
        :class="!arrange ? 'line-chart' : 'line-chart-display'"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
var myChart;
var myChart2;
export default {
  name: "ChartsView",
  props: {
    dataupdate: {
      type: Boolean,
      default: false,
    },
    idata: {
      type: Array,
      default: () => [],
    },
    pfdata: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.initLineChart();
    this.initLineChart2();
  },
  data() {
    return {
      arrange: false,
      fullScreen: false,
      mark: 0,
      clickStatus: "all",
      option: {
        title: {
          text: "电流数据",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        legend: {
          data: ["电流"],
          selected: { 电流: true },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {},
        series: [
          {
            name: "电流",
            type: "line",
            tooltip: {
              valueFormatter: (value) => value + " A",
            },
            stack: "总量",
            smooth: true,
            data: [],
          },
        ],
      },
      option2: {
        title: {
          text: "功率因数",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        legend: {
          data: ["功率因数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {},
        series: [
          {
            name: "功率因数",
            type: "line",
            tooltip: {
              valueFormatter: (value) => value + "",
            },
            stack: "总量",
            smooth: true,
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    initLineChart: function () {
      myChart = echarts.init(document.getElementById("line_chart"));

      myChart.setOption(this.option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    initLineChart2: function () {
      myChart2 = echarts.init(document.getElementById("line_chart2"));

      myChart2.setOption(this.option2);
      window.addEventListener("resize", function () {
        myChart2.resize();
      });
    },
    iDataSelete() {
      myChart2.webkitRequestFullScreen();
      console.log(this.idata);
    },
    arrangeClick() {
      this.arrange = !this.arrange;

      setTimeout(function () {
        myChart.resize();
        myChart2.resize();
      }, 100);
    },
  },
  watch: {
    dataupdate() {
      if (this.idata.length < this.mark) {
        console.log(this.idata.length);
        this.option.xAxis.data = [];
        this.mark = 0;
      } else {
        this.mark = this.idata.length;
      }
      this.option.xAxis.data.push(this.option.xAxis.data.length);
      myChart.setOption({
        xAxis: [
          {
            data: this.option.xAxis.data,
          },
        ],
        series: [
          {
            data: this.idata,
          },
        ],
      });
      myChart2.setOption({
        xAxis: [
          {
            data: this.option.xAxis.data,
          },
        ],
        series: [
          {
            data: this.pfdata,
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
.charts-view {
  margin-left: 173px;
  margin-top: 150px;
  .button-selected {
    color: white;
    background-color: #409eff;
  }
  .line-chart {
    // z-index: 9999999;
    width: 45%;
    height: 400px;
    display: inline-block;
  }
  .line-chart-display {
    width: 100%;
    height: 400px;
    // display: inline;
  }
}
.charts-view-fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // display: none;
  z-index: 99999;
  background-color: #ffffff;
  .button-selected {
    color: white;
    background-color: #409eff;
  }
  .line-chart {
    // z-index: 9999999;
    width: 45%;
    height: 400px;
    display: inline-block;
  }
  .line-chart-display {
    width: 100%;
    height: 400px;
    // display: inline;
  }
}
</style>