import axios from 'axios' // 引入axios
import { ElMessage } from 'element-plus'

const service = axios.create({
    // baseURL: 'http://127.0.0.1:8081',
    baseURL: 'http://dengyue.vip.frp.wlphp.com:88',
    timeout: 99999
})
// http request 拦截器
service.interceptors.request.use(
    config => {
        config.data = JSON.stringify(config.data)
        config.headers = {
            'Content-Type': 'application/json',
        }
        return config
    },
    error => {
        ElMessage({
            showClose: true,
            message: error,
            type: 'error'
        })
        return error
    }
)

// http response 拦截器
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        return error
    }
)

export default service
