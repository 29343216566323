<template>
  <search-area></search-area>
</template>

<script>
import SearchArea from "@/components/SearchArea.vue";
export default {
  name: "HomeView",
  components: {
    SearchArea
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
